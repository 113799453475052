var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.fetchingYear)?_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"my-4",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 primary--text text-center"},[_vm._v("Suppression ou archivage de comptes")])]),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-card',[_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1"}},[_vm._v("Recherche")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v("Sélection des utilisateurs")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v("Résumé")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-form',{ref:"recherche-utilisateur",attrs:{"lazy-validation":""}},[_c('v-select',{staticClass:"pa-1",attrs:{"items":_vm.anneesScolaires,"label":"Année scolaire","clearable":"","rules":[
  function () { return !!_vm.anneeScolaire ||
    'Ce champ est requis'; } ],"required":""},model:{value:(_vm.anneeScolaire),callback:function ($$v) {_vm.anneeScolaire=$$v},expression:"anneeScolaire"}}),_c('v-autocomplete',{staticClass:"pa-1",attrs:{"items":_vm.etablissements,"loading":_vm.loading,"persistent-hint":"","label":"Etablissement *","rules":[
  function () { return !!_vm.etablissement ||
    'Ce champ est requis'; } ],"required":""},model:{value:(_vm.etablissement),callback:function ($$v) {_vm.etablissement=$$v},expression:"etablissement"}}),_c('v-select',{staticClass:"pa-1",attrs:{"items":_vm.classes,"label":"Classes","multiple":"","rules":[
  function () { return !!_vm.classeSelected ||
    'Ce champ est requis'; } ],"required":""},model:{value:(_vm.classeSelected),callback:function ($$v) {_vm.classeSelected=$$v},expression:"classeSelected"}}),_c('v-select',{staticClass:"pa-1",attrs:{"items":_vm.statuts,"label":"Statut des utilisateurs","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('v-select',{staticClass:"pa-1",attrs:{"items":_vm.roles,"label":"Rôle des utilisateurs","clearable":"","multiple":""},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('v-btn',{staticClass:"AccessButton pl-3 pr-3",attrs:{"name":"Rechercher","height":"44px","color":"primary","dark":"","tile":"","loading":_vm.loading,"elevation":"0","fab":"","width":"180"},on:{"click":_vm.validate}},[_vm._v(" Rechercher "),_c('v-spacer'),_c('v-icon',{staticClass:"ml-5"},[_vm._v(" mdi-magnify")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.pageInfo,"headers":_vm.headers,"items":_vm.utilisateurs,"loading":_vm.loading,"server-items-length":_vm.pageInfo.totalItems,"item-key":"identifiant","show-select":""},on:{"update:options":function($event){_vm.pageInfo=$event},"toggle-select-all":_vm.selectAll,"update:sort-by":_vm.changeSort,"update:sort-desc":_vm.changeSortDesc,"update:page":_vm.setPage,"update:items-per-page":_vm.setPageSize},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var expand = ref.expand;
var index = ref.index;
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":_vm.loading,"value":_vm.selectedUsers.map(function (u) { return u.identifiant; }).indexOf(item.identifiant) >= 0,"ripple":false},on:{"click":function($event){return _vm.selectUser(item)}}})]}},{key:"item.actif",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getColor(item.actif)}},[_vm._v(" "+_vm._s(_vm.actifText(item.actif))+" ")])],1)]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}),_c('v-btn',{staticClass:"AccessButton mt-3 mr-3",attrs:{"name":"Suivant","height":"44px","color":"primary","dark":"","tile":"","elevation":"0","loading":_vm.loading,"fab":"","width":"180"},on:{"click":function($event){_vm.currentStep--}}},[_vm._v(" Retour ")]),(_vm.selectedUsers.length > 0)?_c('v-btn',{staticClass:"AccessButton mt-3 mr-3",attrs:{"name":"Suivant","height":"44px","color":"secondary","dark":"","tile":"","elevation":"0","loading":_vm.loading,"fab":"","width":"180"},on:{"click":function($event){_vm.currentStep++}}},[_vm._v(" Suivant ")]):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',[_c('v-col',{staticClass:"text-h5",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 primary--text text-center"},[_vm._v(" Résumé de la suppression ")])]),_c('v-col',{staticClass:"text-h5",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h5 primary--text text-center"},[_vm._v(" Utilisateurs sélectionnés ")])]),_c('v-col',{staticClass:"text-h5",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.selectedUsers,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.actif",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getColor(item.actif)}},[_vm._v(" "+_vm._s(_vm.actifText(item.actif))+" ")])],1)]}}],null,true)})],1)],1),_c('v-btn',{staticClass:"AccessButton mt-3 mr-3",attrs:{"name":"Suivant","height":"44px","color":"primary","dark":"","tile":"","elevation":"0","loading":_vm.loading,"fab":"","width":"180"},on:{"click":function($event){_vm.currentStep--}}},[_vm._v(" Retour ")]),_c('v-btn',{staticClass:"AccessButton mt-3 mr-3",attrs:{"name":"Suivant","height":"44px","color":"secondary","dark":"","tile":"","elevation":"0","loading":_vm.loading,"fab":"","width":"180"},on:{"click":function($event){_vm.dialogConfirmDelete = true}}},[_vm._v(" Suppression ")]),_c('v-btn',{staticClass:"AccessButton mt-3 mr-3",attrs:{"name":"Suivant","height":"44px","color":"secondary","dark":"","tile":"","elevation":"0","loading":_vm.loading,"fab":"","width":"180"},on:{"click":function($event){_vm.dialogConfirmArchive = true}}},[_vm._v(" Archiver ")])],1)],1)],1)],1),_c('ValidationModal',{attrs:{"text":"Êtes-vous sûr de vouloir supprimer les comptes ? (Cette action est irréversible)","status":_vm.dialogConfirmDelete},on:{"validated":_vm.supprimer,"Canceled":_vm.cancel}}),_c('ValidationModal',{attrs:{"text":"Êtes-vous sûr de vouloir archiver les comptes ? (Cette action est irréversible)","status":_vm.dialogConfirmArchive},on:{"validated":_vm.archiver,"Canceled":_vm.cancel}})],1)],1):_c('v-row',{staticStyle:{"height":"80vh"},attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":"100","color":"#fb4d61","indeterminate":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }