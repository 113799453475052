<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <SuppressionTab />
  </v-card>
</template>

<script>
import SuppressionTab from "@/components/SuppressionComponent/SuppressionTab.vue";

export default {
  components: {
    SuppressionTab
  },
};
</script>
